import React, { useEffect } from 'react'
import './assessment.scss';
import { HNW_ASSESSMENT_PATH_KEY } from '../../../../common/commonConstant';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAssessmentList } from '../../../../thunk/hnwHome.thunk';
import AssessmentImageCard from '../../../../common/assessmentImageCard/AssessmentImageCard';
import { RectBadge } from '../../../../common';
import variables from "../../../../common/commonConstant.scss";
import { HOME_PAGE } from '../../../../common/homePageConstants';
import Slider from 'react-slick';

const Assessment = ({ routeTo }) => {

    const dispatch = useDispatch();
    const { assessmentList } = useSelector(state => state.home);

    const settings = {
        dots: false,
        nav: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        initialSlide: 0
    };

    useEffect(() => {
        if (!assessmentList?.length) {
            dispatch(fetchAssessmentList());
        }
    }, []);

    const route = item => {
        if (item.path === HNW_ASSESSMENT_PATH_KEY) {
            routeTo(item.path, `?assessment_id=${item.id}`);
        } else {
            routeTo(item.path);
        }
    }

    const renderList = (item, index) => {
        return (
            <div onClick={() => route(item)} key={`assessment${index}`} className='assessment-card-container'>
                <img src={item?.imageUrl} className='assessment-card-container-image'/>
                <div className='assessment-card-container-content'>
                    <h5 className='assessment-card-container-content-title'>
                        {item?.title}
                    </h5>
                    <button className='assessment-card-container-content-tag'>
                        {HOME_PAGE.ASSESSMENT_BADGE_TEXT}
                    </button>
                </div>
            </div>
        )
    }

    return (
        <div className='assesment-carousel-container'>
            <div className='assessment-carousel-list'>
                <Slider {...settings}>
                    {
                        assessmentList?.length ?
                            assessmentList.map((item, index) => (
                                renderList(item, index)
                            )) : null
                    }
                </Slider>
            </div>
        </div>
    )
}

export default Assessment