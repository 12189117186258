import { combineReducers } from 'redux';
import { default as ProfileReduccer, setRichMenu } from './profileReducer';
import { default as CustomerReducer, find } from './customerReducer';
import BMIReducer from './bmiReducer';
import FoodCalorieReducer from './foodCalorieReducer';
import HnwGoalsReducer from './hnwGoalsReducer';
import HnwCalendarReducer from './hnwCalendarReducer';
import AlertReducer from './alert.reducer';
import CouponReducer from './coupon.reducer';
import PaymentReducer from './paymentReducers';
import { selectedSOP, SOPCart } from './sopReducer';
import {
  policy,
  address,
  addAddress,
  checkrenew,
  cart,
  addItem,
  getStore,
  pickStore,
  delivery,
} from './renewalReducer';
import {
  otp,
  verifyOtp,
  changePhoneNumber,
  createBank,
} from './changeMid.reducer';
import assessment from './assessment.reducer';
import hnwHomeReducer from './hnwHome.reducer';
import hnwGoalSettingReducer from './hnwGoalSetting.reducer';
import foodLogReducer from './foodLogReducer';
import exerciseLogReducer from './exerciseLogReducer';
import challengeTeamDashboardReducer from './challengeTeamDashboardReducer';
import challengeListReducer from './challengeList.reducer';
import challengeVideoReducer from './challenge/challengeVideo.reducer';
import challengeQuizReducer from './challenge/challengeQuiz.reducer';
import hnwFooterReducer from './hnwFooterReducer';
import challengeBodyKeyReducer from './challenge/challengeBodyKey.reducer';
import challengeImageUploadReducer from './challenge/challengeImageUpload.reducer';
import challengeSelfReducer from './challenge/challengeSelf.reducer';
import bodyDataDifferenceReducer from './bodyDataDifference.reducer';

const rootReducer = combineReducers({
  profile: ProfileReduccer,
  setRichMenu,
  customer: CustomerReducer,
  bmi: BMIReducer,
  foodCalorie: FoodCalorieReducer,
  hnwGoals: HnwGoalsReducer,
  hnwCalendar: HnwCalendarReducer,
  alert: AlertReducer,
  coupon: CouponReducer,
  payment: PaymentReducer,
  selectedSOP,
  SOPCart,
  policy,
  address,
  addAddress,
  otp,
  verifyOtp,
  checkrenew,
  cart,
  addItem,
  getStore,
  pickStore,
  find,
  changePhoneNumber,
  createBank,
  delivery,
  assessment,
  home: hnwHomeReducer,
  goalSetting: hnwGoalSettingReducer,
  logList: foodLogReducer,
  exerciseLog: exerciseLogReducer,
  challengeTeamDashboard: challengeTeamDashboardReducer,
  challengeListObj: challengeListReducer,
  challengeVideo: challengeVideoReducer,
  challengeQuiz: challengeQuizReducer,
  footerActiveStatus: hnwFooterReducer,
  challengeBodyKey: challengeBodyKeyReducer,
  challengeImageUpload: challengeImageUploadReducer,
  challengeSelf: challengeSelfReducer,
  bodyDataDifference: bodyDataDifferenceReducer
});

export default rootReducer;
