import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AS_BODY_KEY_VERIFICATION_STEP,
  AS_OTP_VERIFICATION_STEP,
  AUTO_SYNC_MODAL_THAI,
  BODY_KEY_APP_LINK,
  BODY_KEY_APPSTORE,
  BODY_KEY_LOADER_TITLE,
  BODY_KEY_PLAYSTORE,
  BODY_KEY_UNIVERSAL_LINK,
  BODY_KEY_VERIFY_ERROR,
} from '../../challengeConstants';
import {
  generateOtpBodyKeyAction,
  getBodyKeyRecordAction,
  verifyBodyKeyIdAction,
  verifyOtpBodyKeyAction,
} from '../../../actions/challenge/challengeBodyKeyActions';
import AutoSyncVerification from '../autoSyncVerification/AutoSyncVerification';
import CustomModal from '../../customModal/CustomModal';
import HnwSuccessOverlayModal from '../../hnwSuccessOverlayModal/HnwSuccessOverlayModal';
import FloatingWidget from '../floatingWidget/FloatingWidget';
import {
  checkIsIOS,
  thaiNumberLengthValidation,
  thaiPhoneNumberMask,
} from '../../../utill.func';
import moment from 'moment';
import syncAnimation from '../../../json/sync-animation.json';
import './ChallengeAutoSyncFloatingWidget.scss';
import { SET_CUSTOMER_AUTO_SYNC } from '../../../actions/types';

export default function ChallengeAutoSyncFloatingWidget({
  showAutoSync,
  handleAutoSyncToggle,
}) {
  const { isSuccess, loading, phoneNumber, bodyKeyVerifyError } = useSelector(
    (state) => state.challengeBodyKey,
  );
  let phoneNumberMasked = phoneNumber?.split('-')[phoneNumber?.includes("dummy-") ? 1 : 0];
  phoneNumberMasked = thaiPhoneNumberMask(phoneNumberMasked);

  const dispatch = useDispatch();
  const customerData = useSelector((state) => state.customer);
  const [triggerAutoSync, setTriggerAutoSync] = useState(false);
  const [bodyKeyId, setBodyKeyId] = useState('');
  const [bodyKeyOtp, setBodyKeyOtp] = useState('');
  const [validationFailed, setValidationFailed] = useState(false);
  const [otpValidationFailed, setOtpValidationFailed] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorDetail, setErrorDetail] = useState([]);
  const [otpTitle, setOtpTitle] = useState(AUTO_SYNC_MODAL_THAI.OTP_TITLE);
  const [otpErrorDetail, setOtpErrorDetail] = useState([]);
  const [otpTimer, setOtpTimer] = useState(0);
  const [timerInterval, setTimerInterval] = useState(null);
  const [hideLoader, setHideLoader] = useState(false);
  const [amwayNumberError, setAmwayNumberError] = useState(false);
  const [autoSyncStep, setAutoSyncStep] = useState(
    AS_BODY_KEY_VERIFICATION_STEP,
  );

  useEffect(() => {
    if (showAutoSync) setTriggerAutoSync(showAutoSync);
  }, [showAutoSync]);

  useEffect(() => {
    if (parseInt(otpTimer) === 0) {
      clearInterval(timerInterval);
    }
  }, [otpTimer]);

  useEffect(() => {
    if (customerData?.bodyKeyId) {
      loadBodyKeyRecords(customerData?.bodyKeyId);
    }
  }, [customerData?.bodyKeyId]);

  useEffect(() => {
    if (bodyKeyVerifyError && bodyKeyVerifyError?.message) {
      switch (bodyKeyVerifyError?.message) {
        case BODY_KEY_VERIFY_ERROR.NO_FOUND_SUPPORT_CUSTOMER:
          setErrorDetail(AUTO_SYNC_MODAL_THAI.NO_FOUND_SUPPORT_CUSTOMER);
          break;
        case BODY_KEY_VERIFY_ERROR.IN_USE_SUPPORT_CUSTOMER:
          setErrorDetail(AUTO_SYNC_MODAL_THAI.IN_USE_SUPPORT_CUSTOMER);
          break;
        default:
          setErrorDetail([]);
          break;
      }
    }
  }, [bodyKeyVerifyError]);

  const loadBodyKeyRecords = async (bkId) => {
    try {
      await dispatch(
        getBodyKeyRecordAction({
          startDate: moment().format('yyyy-MM-DD'),
          endDate: moment().format('yyyy-MM-DD'),
          bodyKeyId: bkId,
        }),
      );
    } catch (error) {
      console.error('Body Key Recod failed', error);
    }
  };
  const onChangeHandler = (event) => {
    const value = event.target.value;
    switch (autoSyncStep) {
      case AS_BODY_KEY_VERIFICATION_STEP:
        let lengthFailed = thaiNumberLengthValidation(value);
        const pattern = /((\+66|66|0?)(\d{9,10}))/gm;
        setValidationFailed(lengthFailed ? lengthFailed : !pattern.test(value));
        setBodyKeyId(value);
        break;
      case AS_OTP_VERIFICATION_STEP:
        setBodyKeyOtp(value);
        setOtpValidationFailed(value.length !== 4);
        break;
      default:
        break;
    }
  };

  const setDefaultValues = () => {
    setOtpTimer(0);
    setTimerInterval(null);
    if (timerInterval) {
      clearInterval(timerInterval);
    }
  };

  const handleBodyKeyVerificationSuccess = async () => {
    setShowSuccess(true);
    await dispatch({
      type: SET_CUSTOMER_AUTO_SYNC,
      payload: {
        challengeAutoSync: true,
        bodyKeyId: bodyKeyId,
      },
    });
    setTimeout(() => {
      const bkId = bodyKeyId;
      setShowSuccess(false);
      setBodyKeyId('');
      setBodyKeyOtp('');
      loadBodyKeyRecords(bkId);
      setErrorDetail([]);
      setOtpTitle(AUTO_SYNC_MODAL_THAI.OTP_TITLE);
      setOtpErrorDetail([]);
    }, 2000);
  };

  const handleBodyKeyVerificationFailure = () => {
    setAutoSyncStep(AS_BODY_KEY_VERIFICATION_STEP);
    setTriggerAutoSync(true);
    setValidationFailed(true);
    setBodyKeyId(bodyKeyId);
    setBodyKeyOtp('');
    setOtpTitle(AUTO_SYNC_MODAL_THAI.OTP_TITLE);
    setOtpErrorDetail([]);
  };

  const handleBodyKeyVerification = async () => {
    if (customerData?.amwayNumber) {
      setTriggerAutoSync(false);
      handleAutoSyncToggle(false);
      await dispatch(generateOtpBodyKeyAction(customerData?.amwayNumber));
      setAutoSyncStep(AS_OTP_VERIFICATION_STEP);
      setTriggerAutoSync(true);
      setOtpValidationFailed(bodyKeyOtp?.length === 0);
      setDefaultValues();
      setAmwayNumberError(false);
    } else {
      setTriggerAutoSync(true);
      handleAutoSyncToggle(true);
      setAmwayNumberError(true);
    }
  };

  const handleOtpFailure = () => {
    setDefaultValues();
    setOtpTitle(AUTO_SYNC_MODAL_THAI.OTP_FAIL_TITLE);
    setOtpErrorDetail(AUTO_SYNC_MODAL_THAI.OTP_FAIL_DETAIL(phoneNumberMasked));
    setTriggerAutoSync(true);
    setOtpValidationFailed(true);
    setAutoSyncStep(AS_OTP_VERIFICATION_STEP);
  };
  const handleOtpSuccess = async () => {
    setOtpTitle(AUTO_SYNC_MODAL_THAI.OTP_TITLE);
    setOtpErrorDetail([]);
    setDefaultValues();
    const response = await dispatch(verifyBodyKeyIdAction(bodyKeyId));
    if (response?.isSuccess) {
      handleBodyKeyVerificationSuccess();
    } else {
      handleBodyKeyVerificationFailure();
    }
  };

  const handleOtpVerification = async () => {
    setOtpTitle(AUTO_SYNC_MODAL_THAI.OTP_TITLE);
    setTriggerAutoSync(false);
    handleAutoSyncToggle(false);
    setDefaultValues();
    const verifyOtpResponse = await dispatch(
      verifyOtpBodyKeyAction({
        otp: bodyKeyOtp,
        accountId: customerData?.amwayNumber,
      }),
    );
    if (verifyOtpResponse?.statusCode === 200) {
      handleOtpSuccess();
    } else {
      handleOtpFailure();
    }
  };
  const verifyBodyKeyId = async () => {
    switch (autoSyncStep) {
      case AS_BODY_KEY_VERIFICATION_STEP:
        handleBodyKeyVerification();
        break;
      case AS_OTP_VERIFICATION_STEP:
        handleOtpVerification();
        break;
    }
  };

  const handleAutoSyncWidget = () => {
    if (
      isSuccess ||
      (customerData?.challengeAutoSync && customerData?.bodyKeyId)
    ) {
      // NOTE: universal link & app link here
      const isIOS = checkIsIOS();
      const now = new Date().valueOf();
      setTimeout(function () {
        if (new Date().valueOf() - now > 100) return;
        // TODO: CONSOLE NEED TO REMOVE AFTER TESTING
        console.log('BODY_KEY_APP_IN_STORE');
        window.location = isIOS
          ? new URL(BODY_KEY_APPSTORE)
          : new URL(BODY_KEY_PLAYSTORE);
      }, 25);
      // TODO: CONSOLE NEED TO REMOVE AFTER TESTING
      console.log('BODY_KEY_APP');
      window.location = isIOS
        ? new URL(BODY_KEY_UNIVERSAL_LINK)
        : new URL(BODY_KEY_APP_LINK);
    } else {
      setTriggerAutoSync(!triggerAutoSync);
    }
  };

  const resendOtpAction = async () => {
    setHideLoader(true);
    try {
      setOtpTimer(60);
      setTimerInterval(
        setInterval(() => {
          setOtpTimer((prevTimer) =>
            parseInt(prevTimer) === 0
              ? `${prevTimer}`.padStart(2, '0')
              : `${prevTimer - 1}`.padStart(2, '0'),
          );
        }, 1000),
      );
      await dispatch(generateOtpBodyKeyAction(customerData?.amwayNumber));
      setHideLoader(false);
    } catch (error) {
      setHideLoader(false);
    }
  };

  return (
    <div className="auto-sync-floating-widget-container">
      <FloatingWidget onClick={handleAutoSyncWidget}>
        <button className="auto-sync-widget-btn">
          <img
            src={
              isSuccess ||
              (customerData?.challengeAutoSync && customerData?.bodyKeyId)
                ? '/images/challenge/floating-widget-green.svg'
                : '/images/challenge/floating-widget-white.png'
            }
            width={92}
            height={92}
            alt="floating-widget-auto-sync"
          />
        </button>
      </FloatingWidget>
      {loading && !hideLoader && (
        <HnwSuccessOverlayModal
          loop
          show={loading}
          onTimeout={() => {}}
          animationPath={syncAnimation}
          renderDetail={() => (
            <div className="body-key-loader-details">
              {BODY_KEY_LOADER_TITLE}
            </div>
          )}
        />
      )}
      {showSuccess && (
        <CustomModal
          isOpen={showSuccess}
          className="default-modal-size-success"
        >
          <img
            src="/images/challenge/body-key-success.svg"
            alt="auto-sync-success"
          />
        </CustomModal>
      )}
      {triggerAutoSync && (
        <CustomModal
          isOpen={triggerAutoSync}
          className="default-modal-size-auto-sync"
        >
          <AutoSyncVerification
            autoSyncStep={autoSyncStep}
            bodyKeyId={bodyKeyId}
            bodyKeyOtp={bodyKeyOtp}
            validationFailed={validationFailed}
            otpTitle={otpTitle}
            otpErrorDetail={otpErrorDetail}
            otpValidationFailed={otpValidationFailed}
            onChangeHandler={onChangeHandler}
            phoneNumber={phoneNumberMasked}
            amwayNumberError={amwayNumberError}
            errorDetail={errorDetail}
            otpTimer={otpTimer}
            onClose={() => {
              setAutoSyncStep(AS_BODY_KEY_VERIFICATION_STEP);
              setBodyKeyId('');
              setTriggerAutoSync(false);
              handleAutoSyncToggle(false);
              setOtpTitle('');
              setOtpErrorDetail([]);
              setOtpValidationFailed(false);
              setAmwayNumberError(false);
            }}
            onOtpClose={resendOtpAction}
            onVerify={verifyBodyKeyId}
          />
        </CustomModal>
      )}
    </div>
  );
}
