import React from "react";
import { TeamRectCard, commonConstant } from "../../../../common";
import { formatStringWithDecimal, handleWeightFatMuscleBadges, removeMinusFromString, roundOffDecimalVal } from "../../../../utill.func";
import { BODY_DATA_CHANGE } from "../../../../common/homePageConstants";
import "./BodyDataChangeCard.scss";
import BodyCardNoData from "../bodyCardNoData/BodyCardNoData";
import { useHistory } from "react-router-dom";

const BodyDataChangeCard = ({ bodyDiffData, userData }) => {

    const history = useHistory();
    const handleClickWaist = () =>{
        return history.push(commonConstant.pathWaist);
    }
    const handleClickFat = () =>{
        return history.push(commonConstant.pathFat);
    }
    const handleClickMuscle = () =>{
        return history.push(commonConstant.pathMuscle);
    }
    const handleClickHeight = () =>{
        return history.push(commonConstant.pathHeight);
    }
    const bodyNoDataClickHandlers = {
        waist: handleClickWaist,
        fat: handleClickFat,
        muscle: handleClickMuscle,
        height:handleClickHeight
      };

    const bodyDataDifference = {
        waistDiff : bodyDiffData && bodyDiffData["waist"]?.diff,
        fatDiff : bodyDiffData && bodyDiffData["fat"]?.diff,
        muscleDiff : bodyDiffData && bodyDiffData["muscle"]?.diff,
        heightDiff : bodyDiffData && bodyDiffData["height"]?.diff
    }

    const renderBodyDataCard = (
        heading,
        value,
        unit,
        change,
        firstLogCard,
        compositionChange,
        rectTitle,
        muscle, 
        badgeClassname
    ) => {
        return (
            <div className="home-card-body w-50" onClick={bodyNoDataClickHandlers[change]}>
                <TeamRectCard
                    compCard
                    compositionHeading={heading}
                    headingText={value}
                    unit={unit}
                    change={change}
                    firstLogCard={firstLogCard}
                    compositionChange={compositionChange}
                    rectTitle={rectTitle}
                    muscle={muscle}
                    badgeClassname={badgeClassname}
                />
            </div>
        )
    }

    return (
        <div className="home-composition-change-container">
            <div className="align-half-cards">
                {userData?.waist ? renderBodyDataCard(BODY_DATA_CHANGE.homeWaistlineHeading,
                    userData?.waist && formatStringWithDecimal(userData?.waist),
                    BODY_DATA_CHANGE.unitFinger,
                    "waist",
                    bodyDataDifference.waistDiff ? false : true,
                    bodyDataDifference.waistDiff,
                    bodyDataDifference.waistDiff && roundOffDecimalVal(removeMinusFromString((bodyDataDifference.waistDiff))),
                    false,
                    handleWeightFatMuscleBadges(bodyDataDifference.waistDiff, "waist")
                ) : <BodyCardNoData change="waist" />}
                {userData?.fat ? renderBodyDataCard(BODY_DATA_CHANGE.homeFatHeading,
                    userData?.fat && formatStringWithDecimal(userData?.fat),
                    BODY_DATA_CHANGE.unitPercentage,
                    "fat",
                    bodyDataDifference.fatDiff ? false : true,
                    bodyDataDifference.fatDiff,
                    bodyDataDifference.fatDiff && roundOffDecimalVal(removeMinusFromString((bodyDataDifference.fatDiff))),
                    false,
                    handleWeightFatMuscleBadges(bodyDataDifference.fatDiff)
                ) : <BodyCardNoData change="fat" />}
            </div>
            <div className="align-half-cards">
                {userData?.muscle ? renderBodyDataCard(BODY_DATA_CHANGE.homeMuscleHeading,
                    userData?.muscle && formatStringWithDecimal(userData?.muscle),
                    BODY_DATA_CHANGE.unitKilogram,
                    "muscle",
                    bodyDataDifference.muscleDiff ? false : true,
                    bodyDataDifference.muscleDiff,
                    bodyDataDifference.muscleDiff && roundOffDecimalVal(removeMinusFromString((bodyDataDifference.muscleDiff))),
                    true,
                    handleWeightFatMuscleBadges(bodyDataDifference.muscleDiff, "muscle")
                ) : <BodyCardNoData change="muscle" />}
                {userData?.height ? renderBodyDataCard(BODY_DATA_CHANGE.homeHeightHeading,
                    userData?.height && formatStringWithDecimal(userData?.height),
                    BODY_DATA_CHANGE.unitCentimetre,
                    "height",
                    true,
                    bodyDataDifference.heightDiff,
                    bodyDataDifference.heightDiff && roundOffDecimalVal(removeMinusFromString((bodyDataDifference.heightDiff))),
                    false,
                    handleWeightFatMuscleBadges(bodyDataDifference.heightDiff)
                ) : <BodyCardNoData change="height" />}
            </div>
        </div>
    )
}

export default BodyDataChangeCard;