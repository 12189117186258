import React from "react";
import "./WaterLog.scss";
import { calculateScaleLabels, handleWaterLogGlass } from "../../../../utill.func";
import { WATER_CARD_HEADING } from "../../../../common/commonConstant";
import { DEFAULT_WATER_LEVEL } from "../../../../common/homePageConstants";

const WaterLog = ({ waterData }) => {
    const { waterLog } = waterData;
    const { water_level_one, water_level_two, water_level_three } = calculateScaleLabels(waterLog.total/1000);
    const { waterGlassIcon } = handleWaterLogGlass(waterLog.consumed/1000, waterLog.total/1000);
    const water_level_full = waterLog.consumed > waterLog.total ? parseFloat((waterLog.consumed/1000).toFixed(1)) : parseFloat((waterLog.total/1000).toFixed(1));

    return (
        <div class="drink-card-container white-card-container">
            <div class="white-card-title">
                {WATER_CARD_HEADING}
            </div>
            <div class="drink-water-details-container">
                <div class="drink-glass-img-container">
                    <img src={waterGlassIcon} class="glass-scale-img" alt="" />
                    <div className="scale-value-wrapper">
                    <div class="scale-value value1">{water_level_full || DEFAULT_WATER_LEVEL.default_water_l1}</div>
                    <div class="scale-value value2">{water_level_two ? water_level_two : DEFAULT_WATER_LEVEL.default_water_l2}</div>
                    <div class="scale-value value3">{water_level_one ? water_level_one : DEFAULT_WATER_LEVEL.default_water_l3}</div>
                    </div>
                </div>
                <div class="drink-glass-description">
                    <h5 class="used-value">{waterLog.consumed || '-'}</h5>
                    <p class="total-value">/<span>{waterLog.total}</span><span> {waterLog.unit}</span></p>
                </div>
            </div>
        </div>
    )
}

export default WaterLog;