import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addDays, format } from 'date-fns';
import { produce } from 'immer';
import BasicTab from '../../../../shared/tab/BasicTab';
import {
  CALENDAR_DATA_LABELS,
  FOOD_BAR_NAME,
  EXERCISE_LOG_HOME_TITLE_TH,
  exerciseAndTimeSeries,
  HNW_GOAL_SETTING,
  HOME_CALENDAR_TABS_MAPPING,
  LABEL_EN_DAY_CALENDAR_TAB,
  LABEL_EN_MONTH_CALENDAR_TAB,
  LABEL_EN_WEEK_CALENDAR_TAB,
  EXERCISE_BAR_NAME,
  TIME_BAR_NAME,
  GRAPH_TYPE,
  API_DATE_FORMAT,
  EXERCISE_BURN_NAME,
  DAY_XAXIS_MAPPING_WITH_HOURS,
  WEEK_XAXIS_MAPPING_WITH_INDEX,
  HOME_CALENDAR_XAXIS_MAPPING,
} from '../../../../../common/commonConstant';
import {
  HealthRecordingCard,
  HealthSummaryInfoCard,
  HealthDateSelector,
  CalendarLoader,
  Slideup,
} from '../../../../../common';
import {
  fetchExerciseCalendarData,
  fetchFoodCalendarData,
} from '../../../../../actions/hnwCalendarActions';
import {
  Dropdown,
  BarGraphContainer,
  MonthGraphContainer,
  CalenderHeader,
} from '..';
import { calculateDifference, getDataFromSession, getTotalSteps, getDatetimeStamp } from '../../../../../utill.func';
import {
  fixDataForMultibar,
  getMonthRange,
  getWeekRange,
} from '../../../../shared/graph/graph-util';
import LogProgressCard from '../logProgressCard/LogProgressCard';
import {
  extractFoodWaterLogData,
  formattedDropdownValue,
  handleInfinity,
} from '../utility';
import { getHnwAllHealhGoalList } from '../../../../../actions/hnwGoalSettingActions';
import variables from '../../../../../common/commonConstant.scss';
import './ExerciseLogCalendar.scss';



export default function ExerciseLogCalendar({ routeTo }) {
  const CURRENT_DATE_START = new Date();
  CURRENT_DATE_START.setHours(0, 0, 0, 0);
  const CURRENT_DATE_END = new Date(CURRENT_DATE_START);
  CURRENT_DATE_END.setHours(23, 59, 59, 999);
  const { exerciseDark, exerciseLight } = variables;

  const DEFAULT_SELECTED_DATE_ITEM = {
    date: CURRENT_DATE_START,
    exercise: null,
    time: null,
    highlightedLabel: '',
  };

  const DefaultTotalValues = {
    TotalFood: 0,
    TotalExercise: 0,
    TotalTime: 0,
    TotalSteps: 0,
  };

  const DefaultGoalValues = {
    date: CURRENT_DATE_START,
    food: 0,
    exercise: 0,
  };

  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [startEndDate, setStartEndDate] = useState({
    startDate: CURRENT_DATE_START,
    endDate: CURRENT_DATE_END,
  });
  const startEndDateRef = useRef(startEndDate);

  const [healthObj, setHealthObj] = useState(DefaultTotalValues);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const [calendarActiveTab, setCalendarActiveTab] = useState(
    CALENDAR_DATA_LABELS.exercise,
  );

  const [activeTab, setActiveTab] = useState(
    HOME_CALENDAR_TABS_MAPPING.Day.name,
  );
  const [selectedDateItem, setSelectedDateItem] = useState(
    DEFAULT_SELECTED_DATE_ITEM,
  );
  const profileDetails = useSelector((state) => state.profile);
  const healthGoal =
    useSelector((state) => state.goalSetting.healthGoalList) ??
    DefaultGoalValues;

  const foodWaterData = useSelector((state) => state.hnwCalendar?.foodWater);
  const exerciseData = useSelector((state) => state.hnwCalendar?.exercise);

  const foodData = extractFoodWaterLogData(foodWaterData, FOOD_BAR_NAME);

  const foodExerciseData = [...foodData, ...exerciseData];

  const [dataSeries, setDataSeries] = useState(exerciseAndTimeSeries);
  const [monthSeries, setMonthSeries] = useState([]);
  const [counter, setCounter] = useState({ exercise: 0, steps: 0 });

  const calculateGroupedHourlyData = (acc, obj) => {
    const date = new Date(obj.updatedDate);
    let hour = Object.keys(DAY_XAXIS_MAPPING_WITH_HOURS).find((key) =>
      DAY_XAXIS_MAPPING_WITH_HOURS[key].includes(date.getHours()),
    );

    // Check if there is already an entry for the current day of the month
    if (!acc[hour]) {
      // If not, initialize a new entry with default values
      acc[hour] = {
        x: hour,
        exercise: 0,
        time: 0,
      };
    }
    acc[hour].exercise += obj.caloriesBurn || 0;
    acc[hour].time += parseInt(obj.excerciseTime || 0);

    acc.TotalTime += calculateDifference(obj.startTime, obj.endTime);
    acc.TotalExercise += obj.caloriesBurn || 0;
    acc.TotalFood += 0;
    return acc;
  };

  const calculateGroupedWeeklyData = (acc, obj) => {
    const date = new Date(obj.updatedDate);
    let weekDay = WEEK_XAXIS_MAPPING_WITH_INDEX[date.getDay()];
    let weightMultiplyFactor;
    const { myFoodWeight, weight } = obj;
    if (myFoodWeight && weight) {
      weightMultiplyFactor = handleInfinity(myFoodWeight / weight);
    }
    // Check if there is already an entry for the current day of the month
    if (!acc[weekDay]) {
      // If not, initialize a new entry with default values
      acc[weekDay] = {
        x: weekDay,
        exercise: 0,
        time: 0,
      };
    }
    acc[weekDay].exercise += obj.caloriesBurn || 0;
    acc[weekDay].time += parseInt(obj.excerciseTime || 0);

    acc.TotalTime += calculateDifference(obj.startTime, obj.endTime);
    acc.TotalSteps += obj?.distCovered ? getTotalSteps(obj?.distCovered) : 0;
    acc.TotalExercise += obj.caloriesBurn || 0;
    acc.TotalFood += obj.caloriesPerDay * weightMultiplyFactor || 0;
    return acc;
  };

  const calculateGroupedMonthData = (acc, obj) => {
    const date = new Date(obj.updatedDate);
    const dayOfMonth = date.getDate();
    date.setHours(0, 0, 0, 0);
    let weightMultiplyFactor;
    const { myFoodWeight, weight } = obj;
    if (myFoodWeight && weight) {
      weightMultiplyFactor = handleInfinity(myFoodWeight / weight);
    }

    // Check if there is already an entry for the current day of the month
    if (!acc[dayOfMonth]) {
      // If not, initialize a new entry with default values
      acc[dayOfMonth] = {
        date,
        data1: 0,
        // data2: 0,
      };
    }
    acc[dayOfMonth].data1 += obj.caloriesBurn || 0;

    acc.TotalTime += calculateDifference(obj.startTime, obj.endTime);
    acc.TotalSteps += obj?.distCovered ? getTotalSteps(obj?.distCovered) : 0;
    acc.TotalExercise += obj.caloriesBurn || 0;
    acc.TotalFood += obj.caloriesPerDay * weightMultiplyFactor || 0;
    return acc;
  };

  function onTabClick(tab) {
    setActiveTab(tab);
  }

  useEffect(() => {
    switch (activeTab) {
      case HOME_CALENDAR_TABS_MAPPING.Day.name:
        setStartEndDate({
          startDate: CURRENT_DATE_START,
          endDate: CURRENT_DATE_END,
        });
        break;

      case HOME_CALENDAR_TABS_MAPPING.Week.name:
        const { startOfWeek, endOfWeek } = getWeekRange(CURRENT_DATE_START);
        setStartEndDate({ startDate: startOfWeek, endDate: endOfWeek });
        break;

      case HOME_CALENDAR_TABS_MAPPING.Month.name:
        const { startOfMonth, endOfMonth } = getMonthRange(CURRENT_DATE_START);
        setStartEndDate({ startDate: startOfMonth, endDate: endOfMonth });
        break;

      default:
        break;
    }
  }, [activeTab]);

  useEffect(() => {
    setLoading(true);
    startEndDateRef.current = { ...startEndDate };
    const { _id: customerId } = getDataFromSession(
      HNW_GOAL_SETTING.CUSTOMER_SESSION_KEY,
    );
    const fetchData = async () => {
      const commonDatetimeStamp = {
        startDate: getDatetimeStamp(format(startEndDate.startDate, API_DATE_FORMAT)),
        endDate: getDatetimeStamp(format(startEndDate.endDate, API_DATE_FORMAT)),
      }
      await Promise.all([
        dispatch(getHnwAllHealhGoalList(customerId)),
        dispatch(
          fetchFoodCalendarData(commonDatetimeStamp),
        ),
        dispatch(
          fetchExerciseCalendarData(commonDatetimeStamp),
        ),
      ]).finally(() => setLoading(false));
    };
    fetchData();
  }, [startEndDate]);

  useEffect(() => {
    if (dataSeries[0].data.length) {
      const currentInstance = new Date();
      const index = currentInstance.getDay();
      const exercise = dataSeries.find(
        (series) => series.name === EXERCISE_BAR_NAME,
      ).data[index][
        calendarActiveTab === CALENDAR_DATA_LABELS.exercise ? 'y1' : 'y2'
      ];
      setSelectedDateItem({
        date: currentInstance,
        exercise,
      });
    }
  }, [calendarActiveTab]);

  useEffect(() => {
    switch (activeTab) {
      case HOME_CALENDAR_TABS_MAPPING.Day.name: {
        const {
          TotalFood,
          TotalExercise,
          TotalTime,
          TotalSteps,
          ...dailyData
        } = exerciseData.reduce(calculateGroupedHourlyData, DefaultTotalValues);
        const graphData = Object.values(dailyData);
        const newSeries = exerciseAndTimeSeries.map((exerciseTime) =>
          produce(exerciseTime, (draft) => {
            draft.type = GRAPH_TYPE.LINE;
            draft.data = graphData.map((dayData) => {
              return { x: dayData.x, y: dayData.exercise };
            });
            fixDataForMultibar(
              draft.data,
              HOME_CALENDAR_XAXIS_MAPPING.Day,
              undefined,
              null,
            );
          }),
        );
        setDataSeries(newSeries);
        setCounter({ exercise: exerciseData.length, steps: TotalSteps });
        setHealthObj({ TotalExercise, TotalFood, TotalTime });
        const currentInstance = new Date();
        const currentHour = currentInstance.getHours();
        const index = Object.values(DAY_XAXIS_MAPPING_WITH_HOURS).findIndex(
          (item) => item.includes(currentHour),
        );
        const exercise = newSeries.find(
          (series) => series.name === EXERCISE_BAR_NAME,
        ).data[index]?.y;
        setSelectedDateItem({
          date: currentInstance,
          exercise,
          highlightedLabel: HOME_CALENDAR_XAXIS_MAPPING.Day[index],
        });
        break;
      }
      case HOME_CALENDAR_TABS_MAPPING.Week.name: {
        const {
          TotalFood,
          TotalExercise,
          TotalTime,
          TotalSteps,
          ...weeklyData
        } = foodExerciseData.reduce(
          calculateGroupedWeeklyData,
          DefaultTotalValues,
        );
        const graphData = Object.values(weeklyData);
        const newSeries = exerciseAndTimeSeries.map((exerciseTime) =>
          produce(exerciseTime, (draft) => {
            draft.type = GRAPH_TYPE.BAR;
            draft.data = graphData.map((dayData) => {
              const y1 = dayData.exercise;
              const y2 = dayData.time;
              return { x: dayData.x, y1, y2 };
            });
            fixDataForMultibar(draft.data, HOME_CALENDAR_XAXIS_MAPPING.Week, [
              'y1',
              'y2',
            ]);
          }),
        );
        setDataSeries(newSeries);
        setCounter({ exercise: exerciseData.length, steps: TotalSteps });
        setHealthObj({ TotalExercise, TotalFood, TotalTime });
        const currentInstance = new Date();
        const index = currentInstance.getDay();
        const exercise = newSeries.find(
          (series) => series.name === EXERCISE_BAR_NAME,
        ).data[index][
          calendarActiveTab === CALENDAR_DATA_LABELS.exercise ? 'y1' : 'y2'
        ];
        setSelectedDateItem({
          date: addDays(startEndDate.startDate, index),
          exercise,
          highlightedLabel: HOME_CALENDAR_XAXIS_MAPPING.Week[index],
        });
        break;
      }
      case HOME_CALENDAR_TABS_MAPPING.Month.name: {
        const {
          TotalExercise,
          TotalFood,
          TotalTime,
          TotalSteps,
          ...monthlyData
        } = foodExerciseData.reduce(
          calculateGroupedMonthData,
          DefaultTotalValues,
        );
        const CalendarData = Object.values(monthlyData);
        setMonthSeries(CalendarData);
        setCounter({ exercise: exerciseData.length, steps: TotalSteps });
        setHealthObj({ TotalExercise, TotalFood, TotalTime });
        break;
      }
      default:
        break;
    }
  }, [foodWaterData, exerciseData]);

  const newGraphData = dataSeries[0]?.data?.map((item) => ({
    x: item.x,
    y:
      activeTab === HOME_CALENDAR_TABS_MAPPING.Day.name
        ? item.y
        : item[
        calendarActiveTab === CALENDAR_DATA_LABELS.exercise ? 'y1' : 'y2'
        ],
  }));
  activeTab === HOME_CALENDAR_TABS_MAPPING.Week.name &&
    fixDataForMultibar(newGraphData, HOME_CALENDAR_XAXIS_MAPPING.Week);
  const newDataSeries = [{ ...dataSeries[0], data: newGraphData }];

  const handleDateSelect = (item) => {
    setStartEndDate({ startDate: item.startDate, endDate: item.endDate });
    setDropdownOpen(false);
  };

  const onMouseMove = (data, index) => {
    const date = addDays(startEndDateRef.current.startDate, index);
    setSelectedDateItem({
      date,
      exercise: data?.exercise[index]?.y,
      highlightedLabel:
        activeTab === HOME_CALENDAR_TABS_MAPPING.Day.name
          ? HOME_CALENDAR_XAXIS_MAPPING.Day[index]
          : HOME_CALENDAR_XAXIS_MAPPING.Week[index],
    });
  };

  const renderDailyGoalChart = () => (
    <>
      <div className="round-corner-card">
        <LogProgressCard
          type={EXERCISE_BAR_NAME.toLowerCase()}
          value={healthObj.TotalExercise}
          maxValue={healthGoal?.activityGoal?.targetCalories}
          showArrow={false}
        />
      </div>
      <div className="round-corner-card">
        <LogProgressCard
          type={TIME_BAR_NAME.toLowerCase()}
          value={healthObj.TotalTime}
          maxValue={healthGoal?.activityGoal?.exerciseTime}
          showArrow={false}
        />
      </div>
    </>
  )

  const routeToLogList = (type) => {
    type === EXERCISE_BAR_NAME &&
      routeTo(
        'pathExerciseLogList',
        `?startDate=${format(
          startEndDate.startDate,
          API_DATE_FORMAT,
        )}&endDate=${format(startEndDate.endDate, API_DATE_FORMAT)}`,
      );
  };

  return (
    <div className="exercise-log-calendar-container">
      <CalendarLoader showLoading={isLoading} />
      <CalenderHeader routeTo={routeTo} />
      <div className="profile-banner-container">
        <div className="profile-img">
          <img
            src={
              profileDetails?.pictureUrl ??
              '/images/healthAndWellness/profile/profile-img.svg'
            }
            className="profile-img-dimensions"
          />
        </div>
      </div>
      <div className="exercise-log-calendar-container-card">
        <div className="row align-items-center">
          <h2 className="header-typography">{EXERCISE_LOG_HOME_TITLE_TH}</h2>
        </div>

        <BasicTab
          tabs={HOME_CALENDAR_TABS_MAPPING}
          activeTab={activeTab}
          onTabClick={onTabClick}
        />
        <div className="graph-header">
          <h2 className="header-typography">
            {HOME_CALENDAR_TABS_MAPPING[activeTab].heading}
          </h2>
          <Dropdown
            name="date"
            displayText={formattedDropdownValue(
              activeTab,
              startEndDate.startDate,
              startEndDate.endDate,
            )}
            onClick={() => {
              setDropdownOpen(true);
            }}
          />
        </div>
        <HealthDateSelector
          view={activeTab}
          isOpen={isDropdownOpen}
          onClickOK={handleDateSelect}
          startDate={startEndDate.startDate}
          endDate={startEndDate.endDate}
        />
        {
          {
            [LABEL_EN_DAY_CALENDAR_TAB]: (
              <BarGraphContainer
                graphType={GRAPH_TYPE.LINE}
                type={LABEL_EN_DAY_CALENDAR_TAB}
                xAxisCategories={HOME_CALENDAR_XAXIS_MAPPING.Day}
                yAxisCategories={newDataSeries}
                min={startEndDate.startDate}
                max={startEndDate.endDate}
                onMouseMove={onMouseMove}
                highlightedLabel={selectedDateItem.highlightedLabel}
                setActiveTab={setCalendarActiveTab}
                itemTypeList={[
                  {
                    name: EXERCISE_BAR_NAME,
                    value: selectedDateItem.exercise,
                    legendLabel: CALENDAR_DATA_LABELS.exercise_burn.legendLabel,
                    unit: CALENDAR_DATA_LABELS.exercise.unit,
                  },
                ]}
              />
            ),
            [LABEL_EN_WEEK_CALENDAR_TAB]: (
              <BarGraphContainer
                graphType={GRAPH_TYPE.BAR}
                date={selectedDateItem.date}
                type={LABEL_EN_WEEK_CALENDAR_TAB}
                xAxisCategories={HOME_CALENDAR_XAXIS_MAPPING.Week}
                yAxisCategories={newDataSeries}
                min={startEndDate.startDate}
                max={startEndDate.endDate}
                onMouseMove={onMouseMove}
                highlightedLabel={selectedDateItem.highlightedLabel}
                showTabs
                setActiveTab={setCalendarActiveTab}
                activeTab={calendarActiveTab}
                tabList={[EXERCISE_BAR_NAME, TIME_BAR_NAME]}
                itemTypeList={[
                  calendarActiveTab === CALENDAR_DATA_LABELS.exercise
                    ? {
                      name: EXERCISE_BAR_NAME,
                      value: selectedDateItem.exercise,
                    }
                    : { name: TIME_BAR_NAME, value: selectedDateItem.exercise },
                ]}
              />
            ),
            [LABEL_EN_MONTH_CALENDAR_TAB]: (
              <MonthGraphContainer
                typeList={[EXERCISE_BURN_NAME]}
                data={monthSeries}
                min={startEndDate.startDate}
                max={startEndDate.endDate}
                colorCircledark={exerciseLight}
                colorCirclelight={exerciseDark}
                showCircleWithoutDataStrength
                showTabs={false}
              />
            ),
          }[activeTab]
        }
        {activeTab !== HOME_CALENDAR_TABS_MAPPING.Day.name && (
          <>
            <div className="health-summary-info-card-header">
              {HOME_CALENDAR_TABS_MAPPING[activeTab].summary_info_heading}
            </div>
            <div className="health-summary-info-card">
              <HealthSummaryInfoCard
                dataItems={[
                  { componentName: FOOD_BAR_NAME, value: healthObj.TotalFood },
                  {
                    componentName: EXERCISE_BAR_NAME,
                    value: healthObj.TotalExercise,
                  },
                  { componentName: TIME_BAR_NAME, value: healthObj.TotalTime },
                ]}
              />
            </div>
          </>
        )}
        <div className="health-record-card-header">
          {HOME_CALENDAR_TABS_MAPPING[activeTab].recording_heading}
        </div>

        {activeTab === HOME_CALENDAR_TABS_MAPPING.Day.name ? (
          renderDailyGoalChart()
        ) : (
          <>
            <div className="health-recording-card">
              {[EXERCISE_BAR_NAME, 'steps']?.map((itemName) => (
                <React.Fragment key={itemName}>
                  <HealthRecordingCard
                    type={itemName}
                    key={itemName}
                    quantity={counter[itemName]}
                    navigateTo={routeToLogList}
                  />
                </React.Fragment>
              ))}
            </div>
          </>
        )}
      </div>
      <Slideup />
    </div>
  );
}
