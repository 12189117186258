import React from 'react';
import './FoodLogCard.scss'
import { HOME_FOOD_LOG, HOME_FOOD_NO_POINTER, HOME_FOOD_OVER_POINTER } from '../../../../common/homePageConstants';
import { HOME_FOOD_LOG_DATA } from '../../../../common/logListDataDummy/logListData';
import variables from '../../../../common/commonConstant.scss';
import { round } from '../../../../services';
import { encapsulatePercent, formatNumberWithCommas, removeMinusFromString } from '../../../../utill.func';


const FoodLogCard = ({ logData, consumed }) => {
    const remainingCalories = round(logData, 0) - round(consumed, 0);
    const overCalories = remainingCalories < 0 ? "over" : '';
    const foodLogPercentage = round((consumed/logData)*100, 0);
    const pointerPercentage = -90+((foodLogPercentage*180)/100);
    const innerGraphColor = foodLogPercentage == 0 ? 'transparent' : variables.flgreen1;
    const subheading = overCalories ? HOME_FOOD_LOG.overSubtitle : HOME_FOOD_LOG.subtitle;
    const foodLogPercent = overCalories ? encapsulatePercent(foodLogPercentage) : foodLogPercentage;
    const pointerPercent = overCalories ? encapsulatePercent(pointerPercentage) : pointerPercentage;
    const pointerDeflection = overCalories ? HOME_FOOD_OVER_POINTER : HOME_FOOD_NO_POINTER;
    return (
        <div className='home-page-food-log'>
            <div className="calories-progress-container">
                <div className="calories-title">
                    {HOME_FOOD_LOG.header}
                </div>
                <div className="calories-progress-wrapper">
                    <div className="calories-progress-left">
                        <div className="card progress-card progress-card-green-1">
                            <div className="outer-circle">
                                <div className="percent">
                                    <svg viewBox="0 0 73 73">
                                        <defs>
                                            <linearGradient id="semiColor" gradientTransform="rotate(270)">
                                                <stop offset="0%" stopColor={innerGraphColor}></stop>
                                                <stop offset="100%" stopColor={variables.flgreen2}></stop>
                                            </linearGradient>
                                        </defs>
                                        <path d="M36.5,36.5 m-31.5,0 a31.5,31.5 0 1,1 63,0" strokeLinecap="round"
                                            class="background-circle" stroke="#E4EEE8" strokeWidth="9.52" fill="none">
                                        </path>
                                        <path d="M36.5,36.5 m-31.5,0 a31.5,31.5 0 1,1 63,0" strokeLinecap="round"
                                            class="progress-circle" stroke="url(#semiColor)" strokeWidth="9.52"
                                            fill="none"
                                            style={{ "--percent": foodLogPercent, strokeDasharray: 98, strokeDashoffset: "calc(98px - (98px * var(--percent) / 100))" }}>
                                        </path>
                                    </svg>
                                    <div className="number">
                                        <img src="/images/healthAndWellness/home-pointer-icon.png" alt="" style={{ transform: `rotate(calc(${pointerPercent}deg - ${pointerDeflection}deg))`}}/>

                                            <div className="progress-value">
                                            {round(consumed, 0) || '-'}
                                            </div>
                                            <div className="progress-unit">
                                            {HOME_FOOD_LOG.unit}
                                            </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="calories-progress-right">
                        <div className="already-eat-section">
                            <div className="calories-right-title">
                                {HOME_FOOD_LOG.calories}
                            </div>
                            <div className="calories-right-value">
                                <span className="left-value">
                                    {round(consumed, 0) || '-'}
                                </span>
                                <span className="right-value">
                                    /{formatNumberWithCommas(round(logData, 0))}
                                </span>
                                <span className="calories-right-unit">
                                    {HOME_FOOD_LOG.unit}
                                </span>
                            </div>
                        </div>
                        <div className="can-eat-section">
                            <div className="calories-right-title">
                                {subheading}
                            </div>
                            <div className="calories-right-value">
                                <span className={`green ${overCalories}`}>
                                    {removeMinusFromString(remainingCalories)}
                                </span>
                                <span className="calories-right-unit">
                                    {HOME_FOOD_LOG.unit}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FoodLogCard