import React from "react";
import "./HomeExerciseLog.scss";
import { HOME_PAGE } from "../../../../common/homePageConstants";
import variables from '../../../../common/commonConstant.scss';
import { HOME_EXERCISE_LOG_CARD } from "../../../../common/logListDataDummy/logListData";
import { round } from "../../../../services";
import { encapsulatePercent } from "../../../../utill.func";

const HomeExerciseLog = ({ exerciseData }) => {
    const percent = round((exerciseData?.consumed * 100)/exerciseData?.total, 0);
    const innerGraphColor = percent == 0 ? 'transparent' : variables.homeExerciseCircle;

    return (
        <div className="home-exercise-log-container">
            <div className="excercise-card-container white-card-container">
                <div className="white-card-title">
                    {HOME_PAGE.EXERCISE_CARD_TITLE}
                </div>

                <div className="home-exercise-graph"></div>
                <div className="excercise-details-container">
                    <div class="progress-bar-circular">
                        <div className="card progress-card progress-card-green-1">
                            <div className="outer-circle">
                                <div className="percent">
                                    <svg>
                                        <defs>
                                            <linearGradient id="home-exercise-gradient"
                                                gradientTransform="rotate(180)">
                                                <stop offset="0%" stopColor={innerGraphColor}></stop>
                                                <stop offset="100%" stopColor={variables.homeExerciseCircle2}></stop>
                                            </linearGradient>
                                        </defs>
                                        <circle cx="23" cy="23" r="17.5"></circle>
                                        <circle cx="23" cy="23" r="17.5"
                                            style={{ "--percent": encapsulatePercent(percent), stroke: 'url(#home-exercise-gradient)' }}></circle>
                                    </svg>
                                    <div className="number">
                                        <div className="progress-value">
                                            {encapsulatePercent(percent)}%
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="excercise-description">
                        <div class="used-value">{exerciseData?.consumed || '-'}</div>
                        <div className="target">
                        <div class="total-value">{`/${exerciseData?.total || '-'}`}</div>
                        <div className="unit">{HOME_PAGE.EXERCISE_CARD_UNIT}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeExerciseLog;