import { createBrowserHistory } from 'history';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBmiData, updateBmiData } from '../../../actions';
import {
  fetchExerciseCategories,
  fetchExerciseSuggestedMenuList,
  fetchExerciseTimeLogs,
  fetchUserActivityGoal,
} from '../../../actions/exerciseLogActions';
import {
  CalendarLoader,
  HealthCategoryCarousel,
  HealthFoodOverviewCircle,
  HnWNumpadModal,
  HnwFooter,
  HnwHeightWeightInput,
  HnwInputModal,
} from '../../../common';
import {
  EXERCISE_CATEGORY_LIST,
  EXERCISE_DAILY_ACTIVITY,
  EXERCISE_GOAL_CONTAINER,
  EXERCISE_GOAL_LIST,
  EXERCISE_RECOMMENDED_LIST,
  GENDER,
  HNW_GOAL_SETTING,
  H_W_VALIDATION,
  ICON_BURN_EXERCISE,
  LABEL_CALORIES,
  LABEL_EN_MANUAL_LOG_TAB,
} from '../../../common/commonConstant';
import variables from '../../../common/commonConstant.scss';
import HnWSlideup from '../../../common/slideup/HnWSlideup';
import { MyGoalExerciseMenu } from '../../../model/ExerciseLog.model';
import { ExerciseCategory } from '../../../model/GoalSetting.model';
import { round } from '../../../services';
import {
  getCustomerAge,
  getCustomerGender,
} from '../../../services/healthWellness/healthWellness.service';
import { getDefaultValues } from '../../../services/healthWellness/hnwGoals.service';
import {
  getDataFromSession,
  getDatetimeStamp,
  getTodayFormatDate,
  timeMoment,
} from '../../../utill.func';
import Dropdown from '../hnwFoodLog/components/dropdown/Dropdown';
import { ExerciseLogHeader, ExerciseRecommendedList } from './components';
import ExerciseGoalList from './components/exerciseGoalList/ExerciseGoalList';
import HnwExerciseTimeLog from './components/exerciseTimeLog/HnwExerciseTimeLog';
import './ExerciseLogHome.scss';
import { SET_FOOTER_STATE_EXERCISE } from '../../../actions/types';
import { setChallengeType } from '../../../actions/hnwFooterAction';

const ExerciseLogHome = ({ routeTo }) => {
  const dispatch = useDispatch();
  const {
    exerciseCategoryList,
    suggestedExerciseMenuList,
    exerciseTimeLogList,
    userActivities,
  } = useSelector((state) => state?.exerciseLog);
  const customerGender = Object.keys(GENDER).find(
    (item) => GENDER[item] === getCustomerGender(),
  );
  const customerAge = getCustomerAge();
  const bmi = useSelector((state) => state.bmi);
  const defaultValues = getDefaultValues(bmi, customerAge, customerGender);
  const displayDate = getTodayFormatDate(new Date());
  const dateValue = {
    startDate: getDatetimeStamp(moment().format('yyyy-MM-DD')),
    endDate: getDatetimeStamp(moment().format('yyyy-MM-DD')),
  };

  const noCategorySelectedValue = new ExerciseCategory({ _id: '', name: '' });
  const [selectedCategory, setSelectedCategory] = useState(
    noCategorySelectedValue,
  );
  const [openTimeLog, toggleTimeLog] = useState(false);
  const [exerciseMenuId, setExerciseMenuId] = useState('');
  const [myGoalList, setMyGoalList] = useState([]);
  const [todayActivityList, setTodayActivityList] = useState([]);
  const [targetCalorie, setTargetCalorie] = useState(0);
  const [isTargetCalorieCalculated, setIsTargetCalorieCalculated] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [showUserDataModal, setShowUserDataModal] = useState(false);
  const [showNumpad, setShowNumpad] = useState(false);
  const [currentInputType, setCurrentInputType] = useState(null);
  const [activityGoalUpdated, setActivityGoalUpdated] = useState(false);
  const [bmiUpdated, setBmiUpdated] = useState(false);
  const [userDetails, setUserDetails] = useState({
    weight: 0,
    height: 0,
  });

  useEffect(() => {
    setLoading(true);
    const didMount = async () => {
      try {
        const customer = getDataFromSession(
          HNW_GOAL_SETTING.CUSTOMER_SESSION_KEY,
        );
        await dispatch(fetchExerciseTimeLogs(dateValue));
        if (customer?._id) {
          await dispatch(fetchUserActivityGoal(customer?._id));
          setActivityGoalUpdated(true);
        }
        await dispatch(fetchExerciseCategories());
        await dispatch(fetchExerciseSuggestedMenuList());
        setLoading(false);
      } catch (error) {
        setLoading(false);
        throw error;
      }
    };
    dispatch(setChallengeType(SET_FOOTER_STATE_EXERCISE))
    didMount();
  }, []);

  useEffect(() => {
    if (defaultValues?.defaultExercise) {
      setTargetCalorie(defaultValues.defaultExercise);
      setIsTargetCalorieCalculated(true);
    }
  }, [defaultValues?.defaultExercise])

  useEffect(() => {
    if (userActivities?.exerciseCategory?.length) {
      const goalList = userActivities.exerciseCategory
        .flatMap((exercise) => exercise?.exerciseMenu)
        .map((exerciseMenu) => {
          const exerciseLoggedArr = exerciseTimeLogList?.filter(
            (timelog) => timelog?.excerciseCategory?.id === exerciseMenu?.id,
          );
          const exerciseLogged =
            exerciseLoggedArr?.reduce(
              (acc, item, i) => ({
                ...acc,
                ...item,
                caloriesBurn:
                  Number(item.caloriesBurn) + (acc?.caloriesBurn || 0),
                differenceInMinutes:
                  (item.type === LABEL_EN_MANUAL_LOG_TAB
                    ? timeMoment(item?.endTime).diff(
                      timeMoment(item?.startTime),
                      'minutes',
                    )
                    : Number(item?.excerciseTime)) +
                  (acc?.differenceInMinutes || 0),
              }),
              {},
            ) || [];
          return new MyGoalExerciseMenu({
            ...exerciseLogged,
            ...exerciseMenu,
          });
        });
      setMyGoalList(goalList);

      const todayActivityIds = Array.from(
        new Set(
          exerciseTimeLogList.map(
            (exercise) => exercise?.excerciseCategory?.id,
          ),
        ),
      );
      setTodayActivityList(
        todayActivityIds
          .map((menuId) => {
            return exerciseTimeLogList
              .filter((log) => log?.excerciseCategory?.id === menuId)
              .reduce(
                (acc, log, i) => ({
                  ...acc,
                  ...log,
                  caloriesBurn:
                    Number(log?.caloriesBurn) + (acc?.caloriesBurn || 0),
                  noOfTimes: Number(log?.noOfTimes) + (acc?.noOfTimes || 0),
                  duration:
                    timeMoment(log?.endTime).diff(
                      timeMoment(log?.startTime),
                      'minutes',
                    ) + (acc?.duration || 0),
                  distCovered:
                    Number(log?.distCovered) + (acc?.distCovered || 0),
                  catName: log?.excerciseCategory?.exerciseCategory?.name || '',
                }),
                {},
              );
          })
          .filter(
            (log) =>
              !goalList
                ?.map((e) => e.exerciseMenuId)
                ?.includes(log?.excerciseCategory?.id),
          ),
      );
    }
  }, [userActivities, exerciseTimeLogList]);

  useEffect(() => {
    if (userActivities?.targetCalories) {
      setTargetCalorie(userActivities?.targetCalories);
      setIsTargetCalorieCalculated(false);
    } else {
      setTargetCalorie(defaultValues.defaultExercise);
      setIsTargetCalorieCalculated(true);
    }
    if (!userActivities?.targetCalories) {
      if (!bmi?.bmi) {
        dispatch(fetchBmiData()).then(() => {
          setBmiUpdated(true);
        });
      } else {
        setBmiUpdated(true);
      }
    }
  }, [userActivities?.targetCalories]);

  useEffect(() => {
    if (activityGoalUpdated && bmiUpdated) {
      const { weight, height } = bmi;

      if (weight || height) {
        setUserDetails((prevData) => ({
          ...prevData,
          ...(weight && { weight }),
          ...(height && { height }),
        }));
      }
      setCurrentInputType(weight && !height ? 'height' : 'weight');

      if ((!weight || !height) && !userActivities?.targetCalories) {
        setShowNumpad(true);
        setShowUserDataModal(true);
      }
    }
  }, [activityGoalUpdated, bmiUpdated]);

  const handleCategoryClick = (item) => {
    setSelectedCategory(item);
    routeTo('pathHnwExerciseCategoryList', `?exerciseCatId=${item.id}`);
  };

  const handleNumpadSubmit = () => {
    const { weight, height } = userDetails;
    if (weight && height) {
      const payload = Object.assign({}, userDetails, {
        height: Number(userDetails.height),
        weight: Number(userDetails.weight),
      });
      setLoading(true);
      setShowUserDataModal(false);
      dispatch(updateBmiData(payload)).finally(() => setLoading(false));
    }
    setShowNumpad(false);
    setCurrentInputType(null);
  };

  const handleNumpadValueChange = (val, type) => {
    const regex =
      currentInputType === 'weight'
        ? H_W_VALIDATION.weight.pattern
        : H_W_VALIDATION.height.pattern;
    const newValue =
      type !== 'delete' ? (userDetails[currentInputType] || '') + val : val;
    if (regex.test(newValue)) {
      setUserDetails((prevData) => ({
        ...prevData,
        [currentInputType]: newValue,
      }));
    }
  };

  const handleNumpadDelete = () => {
    const newValue = userDetails[currentInputType].toString().slice(0, -1);
    handleNumpadValueChange(newValue, 'delete');
  };

  const handleInputBoxClick = (name) => {
    setCurrentInputType(name);
    if (!showNumpad) {
      setShowNumpad(true);
    }
  };

  return (
    <>
      <CalendarLoader showLoading={loading} />
      <div
        className={`exercise-log-home-container${openTimeLog ? ' --no_scroll' : ''
          }`}
      >
        {
          <>
            <div className="exercise-log-custom-modal">
              <HnWNumpadModal openNumpad={openTimeLog}>
                <HnwExerciseTimeLog
                  toggleTimeLog={toggleTimeLog}
                  setExerciseMenuId={setExerciseMenuId}
                  routeTo={routeTo}
                  exerciseMenuId={exerciseMenuId}
                  exerciseTimeLogList={exerciseTimeLogList}
                  isTargetCalorieCalculated={isTargetCalorieCalculated}
                  targetCalorie={targetCalorie}
                />
              </HnWNumpadModal>
            </div>
            <div className="exercise-log-home-header">
              <ExerciseLogHeader routeTo={routeTo} />
            </div>
            <div className="exercise-overview-banner-container">
              <div className="exercise-overview-banner-content">
                <div className="exercise-overview-banner-header">
                  <div className="exercise-overview-heading">การเผาผลาญ</div>
                  <Dropdown displayText={displayDate} disabled />
                </div>
                <HealthFoodOverviewCircle
                  maxValue={round(targetCalorie, 0) || 0}
                  progressValue={round(
                    exerciseTimeLogList.reduce(
                      (accumulator, timelog) =>
                        accumulator + timelog.caloriesBurn,
                      0,
                    ),
                    0,
                  )}
                  label={LABEL_CALORIES}
                  icon={ICON_BURN_EXERCISE}
                  iconMax={ICON_BURN_EXERCISE}
                  strokeColor={variables.exerciseCircleStroke}
                />
              </div>
            </div>
            <div className="activity-category-container">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    {!myGoalList.length ? (
                      <div className="your-goal-container">
                        <div className="header">
                          {EXERCISE_GOAL_CONTAINER.header}
                        </div>
                        <img
                          src="/images/healthAndWellness/exercise-log/goal-img.svg"
                          className="goal-img"
                        />
                        <div className="your-goal-card">
                          <div className="your-goal-card-wrapper">
                            <div className="your-goal-details">
                              <div className="your-goal-description">
                                <span>{EXERCISE_GOAL_CONTAINER.content}</span>
                                <span>{EXERCISE_GOAL_CONTAINER.info}</span>
                              </div>
                              <button className="btn btn-primary">
                                <img src="/images/healthAndWellness/exercise-log/icons/goal-white.svg" />
                                <span
                                  onClick={() => routeTo('pathHnwGoalsHome')}
                                >
                                  {EXERCISE_GOAL_CONTAINER.btn}
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="exercise-goal-container">
                        <ExerciseGoalList
                          headerText={EXERCISE_GOAL_LIST.header}
                          toggleTimeLog={toggleTimeLog}
                          setExerciseMenuId={setExerciseMenuId}
                          exerciseTimeLogList={exerciseTimeLogList}
                          list={myGoalList}
                          routeTo={routeTo}
                        />
                      </div>
                    )}
                    {todayActivityList.length > 0 && (
                      <div className="exercise-daily-activity-container">
                        <ExerciseRecommendedList
                          header={EXERCISE_DAILY_ACTIVITY.header}
                          toggleTimeLog={toggleTimeLog}
                          setExerciseMenuId={setExerciseMenuId}
                          exerciseTimeLogList={exerciseTimeLogList}
                          data={todayActivityList}
                          routeTo={routeTo}
                          isTargetCalorieCalculated={isTargetCalorieCalculated}
                          targetCalorie={targetCalorie}
                        />
                      </div>
                    )}
                    <div className="activity-list-container">
                      <div className="header">
                        {EXERCISE_CATEGORY_LIST.header}
                      </div>
                      <div className="activity-list-container-wrapper slick-slider">
                        <HealthCategoryCarousel
                          data={exerciseCategoryList}
                          selectedCategory={selectedCategory}
                          onClick={handleCategoryClick}
                        />
                      </div>
                    </div>
                    <div className="recommended-exercise">
                      <ExerciseRecommendedList
                        isRecommended
                        header={EXERCISE_RECOMMENDED_LIST.header}
                        exerciseMenuId={selectedCategory.id || ''}
                        data={suggestedExerciseMenuList}
                        routeTo={routeTo}
                        isTargetCalorieCalculated={isTargetCalorieCalculated}
                        targetCalorie={targetCalorie}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      </div>
      <HnWSlideup />
      <HnwInputModal
        isOpen={showUserDataModal}
        onBackgroundClick={handleNumpadSubmit}
        onNumpadValueChange={handleNumpadValueChange}
        onNumpadDelete={handleNumpadDelete}
        showNumpad={showNumpad}
        onNumpadSubmit={handleNumpadSubmit}
      >
        <HnwHeightWeightInput
          userHeight={userDetails.height}
          userWeight={userDetails.weight}
          handleInputBoxClick={handleInputBoxClick}
        />
      </HnwInputModal>
      <HnwFooter />
    </>
  );
};

export default ExerciseLogHome;
