import React from 'react';
import variables from '../../../common/commonConstant.scss';

const ChallengeCircularProgress = ({ value, percent, total, unit }) => {
  return (
    <div className="take-step-today-right-section">
      <div className="card progress-card progress-card-green-1">
        <div className="outer-circle">
          <div className="percent">
            <div className="inner-circle">
              <svg>
                <defs>
                  <linearGradient id="gradient" gradientTransform="rotate(180)">
                    <stop
                      offset="0%"
                      stopColor={
                        percent === 0 ? 'transparent' : variables.flgreen1
                      }
                    ></stop>
                    <stop offset="100%" stopColor={variables.flgreen2}></stop>
                  </linearGradient>
                </defs>
                <circle cx="139.154" cy="141.346" r="84.8077"></circle>
                <circle
                  cx="139.154"
                  cy="141.346"
                  r="84.8077"
                  style={{
                    '--percent': percent,
                    stroke: 'url(#gradient)',
                  }}
                ></circle>
              </svg>
            </div>
            <div className="circle-progress-detail">
              <div className="progress-value">
                {value || 0}
              </div>
              <div>
                <span className="progress-total">/{total}</span>{' '}
                <span className="progress-unit">{unit}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChallengeCircularProgress;
