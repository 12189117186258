import React from "react";
import { formatNumberWithCommas, inactiveClassName } from "../../../utill.func";
import RectBadge from "../../rectBadge/RectBadge";
import './TeamRectCard.scss'
import variables from '../../../common/commonConstant.scss'
import { CHALLENGE, ChallengeTeamdashboardStatus } from "../../challengeConstants";
import { BmiCirclesSvg, UpArrowSvg, DownArrowSvg } from "../../../common";

const TeamRectCard = ({
    title,
    quantity,
    unit,
    cardLocation,
    imgIcon,
    imgArrow,
    headingText,
    medalsWon,
    totalMedals,
    content,
    amount,
    stepsUnit,
    badgeIcon,
    rectTitle,
    subHeadingText,
    bodyCard,
    vdoCard,
    compCard,
    firstLogCard,
    compositionHeading,
    teamDashboardStatus,
    badgeClassname,
    bmiBadgeIconColor,
    muscle,
    compositionChange,
    change,
    medalPoints
}) => {

    const bodyCardCass = bodyCard ? "bodyClass" : "";
    const activeColorCheck = inactiveClassName(teamDashboardStatus, 'disable-color', 'active-color');
    const greenColorCheck = inactiveClassName(teamDashboardStatus, 'disable-color', 'bmi-green');
    const removeTeamStatus = teamDashboardStatus == ChallengeTeamdashboardStatus.remove_team;
    const inactiveBadgeClassName = {classname: 'inactive-badge'}

    const renderBmiSvg = () => {
        return (
            removeTeamStatus ? <BmiCirclesSvg bmiColor={variables.inactiveBadgeContent} /> :
            <BmiCirclesSvg bmiColor={bmiBadgeIconColor} />
        )
    }

    const renderYoutubeDetailsHeader = () => {
        return (
            <div className="youtube-details-header">
                <img src={imgIcon} alt="" />
                <span>
                    <img src={imgArrow} className="arrow-left" alt="" />
                </span>
            </div>
        )
    }

    const renderTeamCompositionValue = () => {
        return (
            <div className="team-composition-value">
                {unit ?
                    <>
                        <span className={`team-composition-heading-font ${activeColorCheck}`}>{headingText}</span>
                        <span className="team-composition-unit-font">{unit}</span>
                    </>
                    : <div className="composition-bmi-fonts">
                        <span className="team-composition-heading-font">{headingText}</span>
                        <span className="composition-slash">/</span>
                        <span className="composition-max-team-members">{CHALLENGE.teamDashboard.maxTeamMembers}</span>
                    </div>
                }
            </div>
        )
    }

    const renderMedalsContent = () => {
        return (
            <div className="badge-content-align">
                <span className={greenColorCheck}>{medalsWon}</span>
                <span className={`badge-divide-font ${activeColorCheck}`}>/</span>
                <span className={activeColorCheck}>{subHeadingText}</span>
            </div>
        )
    }

    const renderDoubleStepCardContent = () => {
        return (
            <div className="step-card-left-col2">
                <span className={greenColorCheck}>{formatNumberWithCommas(quantity) || 0}</span>
                <span>{unit}</span>
            </div>
        )
    }

    const renderTripleStepCardContent = () => {
        return (
            <div className={`step-card-left-col3 ${activeColorCheck}`}>
                {content && <span>{content}</span>}
                {amount && <span className={`average-steps-qty ${activeColorCheck}`}>{formatNumberWithCommas(amount) || amount}</span>}
                {stepsUnit && <span>{stepsUnit}</span>}
            </div>
        )
    }

    const renderUpAndDownArrows = () => {
        let arrowIcon = ''
        const inactiveDownArrow = <DownArrowSvg arrowColor={variables.inactiveBadgeContent} />;
        const inactiveUpArrow = <UpArrowSvg arrowColor={variables.inactiveBadgeContent} />;
        if (muscle) {
            if (compositionChange <= 0) {
                arrowIcon = removeTeamStatus ? inactiveDownArrow : <DownArrowSvg arrowColor={variables.darkRed} />;
            } else {
                arrowIcon = removeTeamStatus ? inactiveUpArrow : <UpArrowSvg arrowColor={variables.arrowUp} />;
            }
        } 
        else if (change == "waist") {
            if (compositionChange <= 0) {
                arrowIcon = removeTeamStatus ? inactiveUpArrow : <UpArrowSvg arrowColor={variables.darkRed} />;
            }
            else {
                arrowIcon = removeTeamStatus ? inactiveUpArrow : <UpArrowSvg arrowColor={variables.arrowUp} />;
            }
        }
        else {
            if (compositionChange <= 0) {
                arrowIcon = removeTeamStatus ? inactiveDownArrow : <DownArrowSvg arrowColor={variables.arrowUp} />;
            } else {
                arrowIcon = removeTeamStatus ? inactiveUpArrow : <UpArrowSvg arrowColor={variables.darkRed} />;
            }
        }
        return arrowIcon;
    }

    const _renderCompositionCard = () => {
        return (
            <>
                <div className="team-composition-heading">{compositionHeading}</div>
                {renderTeamCompositionValue()}
                {!firstLogCard &&
                    <RectBadge
                        rectTitle={rectTitle}
                        rectIcon={subHeadingText}
                        textColor={variables.challengeSuccessGreen}
                        bgColor={variables.challengeDetailGreen}
                        rectUnit={unit}
                        badgeClassname={removeTeamStatus ? inactiveBadgeClassName : badgeClassname}
                    >
                        <div className="rect-badge-description">
                            {bmiBadgeIconColor ? renderBmiSvg() : renderUpAndDownArrows()}
                            <div className='rect-badge-title'>{rectTitle}</div>
                            <div className='rect-badge-value'>{unit}</div>
                        </div>
                    </RectBadge>
                }
            </>
        )
    }

    const _renderVdoCard = () => {
        return (
            <>
                {renderYoutubeDetailsHeader()}
                <div className="team-composition-heading">{headingText}</div>
                {medalPoints ? renderMedalsContent(): <div className="team-composition-heading">{subHeadingText}</div>}
            </>
        )
    }

    const renderHalfCard = () => {
        return (
            <div>
                {vdoCard ? _renderVdoCard() : _renderCompositionCard()}
            </div>
        )
    }

    const renderFullCard = () => {
        return (
            <div className="step-card-left">
                <div className={`step-card-left-col1 ${bodyCardCass}`}>{title}</div>
                {renderDoubleStepCardContent()}
                {renderTripleStepCardContent()}
            </div>
        )
    }

    return (
        <div className="team-rect-card-container">
            {(vdoCard || compCard || firstLogCard) ? renderHalfCard() : renderFullCard()}
        </div>
    );

}

export default TeamRectCard;